import React from 'react';
import styled from '@emotion/styled';

import { Box, BoxProps } from '@/components/base/box';
import { PositionProps, SpaceProps } from '@/types/theme-types';

const StyledBounceBox = styled(Box)`
  width: 14px;
  height: 14px;
  background-color: #5da34e;
  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
`;

const StyledBounceBox1 = styled(StyledBounceBox)`
  animation-delay: -0.16s;
`;

const StyledBounceBox2 = styled(StyledBounceBox)`
  animation-delay: -0.16s;
`;

const StyledWrap = styled(Box)<BoxProps>`
  width: 70px;
  text-align: center;
`;

export type CircleLoaderProps = SpaceProps & PositionProps & {};

export const CircleLoader: React.FC<CircleLoaderProps> = (props) => {
  return (
    <StyledWrap {...props}>
      <StyledBounceBox1></StyledBounceBox1>
      <StyledBounceBox2></StyledBounceBox2>
      <StyledBounceBox></StyledBounceBox>
    </StyledWrap>
  );
};
