import { TextInput, TextInputProps } from '@/components/base/text-input';
import { useField, FieldProps, FieldHookConfig } from 'formik';
import { omit } from 'lodash';
export interface FormikTextInputFieldProps<T = any> extends TextInputProps {
  fieldProps?: Pick<FieldHookConfig<T>, 'validate'>;
}

export const FormikTextInputField = <T extends any = any>(
  props: FormikTextInputFieldProps<T>,
) => {
  const textProps = omit(props, ['fieldProps']);
  const [field, meta] = useField({
    ...textProps,
    ...props.fieldProps,
  });
  return (
    <>
      <TextInput
        {...field}
        {...textProps}
        error={meta.touched && meta.error ? meta.error : ''}
      />
    </>
  );
};
