import styled from '@emotion/styled';
import { Text, TextProps } from '@/components/base/text';

export interface ErrorProps extends TextProps {}

export const Error = styled(Text)<ErrorProps>`
  color: ${({ theme }) => theme.colors.error};
`;

Error.defaultProps = {
  as: 'span',
  variant: 'xs',
};
