import { Image } from '@/components/base/image';
import { forwardRef } from 'react';

const DNT_LOGO_WIDTH = ['135px', '135px', '173px', '173px'];
const DNT_LOGO_HEIGHT = ['54px', '54px', '69px', '69px'];

export const Logo = forwardRef(function Logo(
  { width }: { width?: string },
  ref,
) {
  return (
    <Image
      cursor="pointer"
      src="/images/dntLogo.png"
      alt="Dinner Time Logo"
      width={width || DNT_LOGO_WIDTH}
      height={width ? 'auto' : DNT_LOGO_HEIGHT}
    />
  );
});
