import React, { RefObject, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import css, { SystemStyleObject } from '@styled-system/css';
import { Box } from '@/components/base/box';
import { Text } from '@/components/base/text';
import { Error } from '@/components/base/error';
import {
  HelperColorProps,
  LabelProps,
  TextInputProps,
  WrapperProps,
} from './types';
import { INPUT_HEIGHT } from './constants';
import { InputVariantKey } from '@/types/theme-types';
import { TextProps } from '@/components/base/text/Text';
import { TextButton } from '@/components/base/buttons';
import { CircleLoader } from '@/components/base/circle-loader';

export const getInputBorderColor = ({
  theme,
  disabled,
  error,
  variant = 'primary',
}: HelperColorProps) => {
  if (disabled) return theme.input?.variants?.[variant]?.disabledColor;
  if (error) return theme.input.variants?.[variant].errorColor;
  return theme.input.variants?.[variant]?.borderColor;
};

export const getInputFocusedBorderColor = ({
  theme,
  disabled,
  error,
  variant = 'primary',
}: HelperColorProps) => {
  if (disabled) return theme.input?.variants?.[variant].disabledColor;
  if (error) return theme.input?.variants?.[variant].errorColor;
  return theme.input?.variants?.[variant].borderFocusColor;
};

export const getInputHoveredBorderColor = ({
  theme,
  disabled,
  error,
  variant = 'primary',
}: HelperColorProps) => {
  if (disabled) return theme.input?.variants?.[variant].disabledColor;
  if (error) return theme.input?.variants?.[variant].errorColor;
  return theme.input?.variants?.[variant].borderHoverColor;
};

const getLabelColor = ({
  disabled,
  error,
  theme,
  variant = 'primary',
}: HelperColorProps) => {
  if (disabled) return theme.input?.variants?.[variant].disabledLabelColor;
  if (error) return theme.input?.variants?.[variant].errorColor;
  return theme.input?.variants?.[variant].labelColor;
};

const LabelBaseStyles = css({
  fontSize: '4xs',
  fontWeight: 'regular',
  lineHeight: '4xs',
} as SystemStyleObject);

export const Label = styled.label<LabelProps & { variant: InputVariantKey }>`
  ${LabelBaseStyles};
  color: ${(props) => getLabelColor(props)};
  margin-left: ${(props) => props.theme.space.s}px;

  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;

export const Helper = styled(Text)<
  TextProps & { inputVariant: InputVariantKey }
>`
  color: ${({ theme, inputVariant = 'primary' }) =>
    theme.input?.variants?.[inputVariant].helperColor};
`;

export const getStyledInputBaseStyles = (variant: InputVariantKey) =>
  css({
    fontWeight: variant === 'primary' ? '300' : 'regular',
    lineHeight: '5xs',
    display: 'block',
    outline: 'none',
    borderRadius: '2xs',
    width: '100%',
    height: INPUT_HEIGHT,
    boxSizing: 'border-box',
  } as SystemStyleObject);

const StyledInput = styled.input<WrapperProps>`
  ${({ variant = 'primary' }) => getStyledInputBaseStyles(variant)};
  display: block;
  color: ${({ theme, variant = 'primary' }) =>
    theme.input.variants?.[variant]?.color};
  border: 1px solid ${(props) => getInputBorderColor(props)};
  padding: ${({ theme }) => theme.space.xs}px ${({ theme }) => theme.space.s}px;

  &:hover {
    box-shadow: 0 0 8px ${(props) => getInputFocusedBorderColor(props)};
  }

  &:focus {
    box-shadow: 0 0 8px ${(props) => getInputHoveredBorderColor(props)};
  }

  background-color: ${({ theme, variant = 'primary' }) =>
    theme.input.variants?.[variant]?.backgroundColor};

  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;

  /* Removes chrome autofill color */
  -webkit-box-shadow: 0 0 0px 1000px white inset;

  /* Change text in autofill textbox*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
`;

const StyledClearTextButton = styled(TextButton)`
  position: absolute;
  right: 8px;
  top: 8px;
  line-height: ${({ theme }) => theme.lineHeights['2xs']};
  color: ${({ theme }) => theme.colors.textInputClear};

  ${({ theme }) => theme.mediaQueries.m} {
    font-size: ${({ theme }) => theme.fontSizes['2xl']};
  }

  &:hover {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    color: ${({ theme }) => theme.colors.textInputClearHover};
  }
`;

export const TextInput: React.FC<TextInputProps> = ({
  label,
  error,
  helper,
  disabled,
  variant = 'primary',
  showClearText,
  onClearTextClick = () => {},
  focusOnError = false,
  // eslint-disable-next-line react-hooks/rules-of-hooks
  inputRef = useRef<HTMLInputElement>(null),
  loading = false,
  ...props
}) => {
  useEffect(() => {
    if (
      error &&
      focusOnError &&
      inputRef?.current &&
      typeof inputRef.current?.focus === 'function'
    ) {
      inputRef.current.focus();
    }
  }, [error]);

  return (
    <Box width={props.width} position={'relative'}>
      {Boolean(label) && (
        <Label
          disabled={disabled}
          error={error}
          htmlFor={props?.id}
          variant={variant}
        >
          {label}
        </Label>
      )}
      <StyledInput
        type="text"
        disabled={disabled}
        error={error}
        {...props}
        variant={variant}
        ref={inputRef}
      />

      {(error || helper) && (
        <Box minHeight="22px" marginTop="2xs">
          {/* eslint-disable-next-line no-nested-ternary */}
          {error ? (
            <Error
              as="span"
              variant="4xs"
              ml="s"
              data-testid={`input-error-${props.name}`}
            >
              {error}
            </Error>
          ) : helper ? (
            <Helper
              as="span"
              variant="4xs"
              ml="s"
              inputVariant={variant}
              data-testid={`input-helper-${props.name}`}
            >
              {helper}
            </Helper>
          ) : null}
        </Box>
      )}

      {loading && <CircleLoader position={'absolute'} right={'m'} top={'xs'} />}

      {showClearText && (
        <StyledClearTextButton
          onClick={(e) => {
            e.preventDefault();
            onClearTextClick();
          }}
        >
          ×
        </StyledClearTextButton>
      )}
    </Box>
  );
};
