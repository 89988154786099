import styled from '@emotion/styled';
import { Flex } from '../flex';

export interface CenterProps {
  /**
   * Centers child horizontally.
   */
  horizontal?: boolean;
  /**
   * Centers child vertically.
   */
  vertical?: boolean;
}

const defaultFlexPropertyValues = 'flex-start';

/**
 * A layout component that lets you vertically
 * & horizontally center it's children.
 */
export const Center = styled(Flex)<CenterProps>`
  align-items: ${({ vertical }) =>
    vertical ? 'center' : defaultFlexPropertyValues};
  justify-content: ${({ horizontal }) =>
    horizontal ? 'center' : defaultFlexPropertyValues};
`;

Center.defaultProps = {
  horizontal: true,
  vertical: true,
};
